import PropTypes from 'prop-types';
import NextLink from 'next/link';
import styles from './tag.module.css';

const Tag = ({ type, className, href, children }) => {
  let classNames = [styles.tag];
  if (className) classNames.push(className);
  if (type) classNames.push(styles[type]);

  if (href) {
    return (
      <NextLink href={href}>
        <a className={classNames.join(' ')}>{children}</a>
      </NextLink>
    )
  }

  return (
    <span className={classNames.join(' ')}>
      {children}
    </span>
  );
};

Tag.propTypes = {
  className: PropTypes.string
}

Tag.defaultProps = {
  className: ''
};

export default Tag;