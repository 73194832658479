import PropTypes from 'prop-types';
import styles from './grid.module.css';

const Grid = ({ columns, columnsSm, columnsMd, columnsLg, rows, center, spacing, children }) => {
  let classNames = [styles.grid];
  if (center) classNames.push(styles.center);
  if (spacing) classNames.push(styles.spacing);

  if (columns) {
    const columnName = `grid-cols-${columns}`;
    classNames.push(styles[columnName]);
  }

  if (columnsSm) {
    const columnName = `grid-cols-sm-${columnsSm}`;
    classNames.push(styles[columnName]);
  }

  if (columnsMd) {
    const columnName = `grid-cols-md-${columnsMd}`;
    classNames.push(styles[columnName]);
  }

  if (columnsLg) {
    const columnName = `grid-cols-lg-${columnsLg}`;
    classNames.push(styles[columnName]);
  }

  if (rows) {
    const columnName = `grid-rows-${columns}`;
    classNames.push(styles[columnName]);
  }

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  columns: PropTypes.number,
  columns: PropTypes.number,
  center: PropTypes.bool,
  spacing: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

export default Grid;