import { Link } from 'project/atoms';
import styles from './logo.module.css';

const Logo = ({ size }) => {
  let classNames = [styles.logo];
  if (size) classNames.push(styles[size]);

  return (
    <Link href='/' className={classNames.join(' ')}>
      <span className={styles.part1}>CSGO</span>
      <span className={styles.part2}>STRATBOOK</span>
    </Link>
  );
};

export default Logo;