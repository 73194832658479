import PropTypes from 'prop-types';
import { Heading, Link, Paragraph, Image, Container } from 'project/atoms';
import styles from './card.module.css';

const ImageCard = ({ title, abstract, image, href, readMore, className }) => {
  return (
    <div className={`row equal ${className} ${styles.card}`}>
      <div className='col-xs-12 col-sm-6'>
        {image && <div className={styles.image}><Image src={image} /></div>}
      </div>
      <div className='col-xs-12 col-sm-6'>
        {title && <Heading level='h4' className={styles.title}>{title}</Heading>}
        {abstract && <Paragraph>{abstract}</Paragraph>}
        {href && readMore && <Link href={href}>{readMore}</Link>}
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  abstract: PropTypes.string,
  image: PropTypes.string,
  href: PropTypes.string
};

ImageCard.defaultProps = {
  className: '',
  card: {
    href: '#'
  }
};

export default ImageCard;