import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaData = (props) => {
  const metaTitle = props.title || '';
  const ogTitle = props.title || '';
  const metaDescription = props.description || '';
  const ogDescription = props.description || '';

  return (
    <Helmet>
      <meta charSet={props.charSet || charSet} />
      <title>{props.title || title}</title>
      <meta name='title' content={props.metaTitle || metaTitle} />
      <meta name='description' content={props.metaDescription || metaDescription} />
      <meta name='og:title' content={props.ogTitle || ogTitle} />
      <meta name='og:description' content={props.ogDescription || ogDescription} />
      {typeof window !== 'undefined' ? 
        <link rel='canonical' href={props.canonical} />
      : ''}
    </Helmet>
  );
};

MetaData.propTypes = {
  charSet: PropTypes.string,
  title: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  canonical: PropTypes.string
};

MetaData.defaultProps = {
  charSet: 'utf-8',
  canonical: typeof window !== 'undefined' ? window.location.origin + '/' + window.location.pathname : '',
  title: 'CSGOStratbook',
  description: ''
};

export default MetaData;