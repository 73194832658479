import { Button, Icon } from 'project/atoms';
import PropTypes from 'prop-types';
import styles from './icon-button.module.css';
import { FaceitIcon, SteamIcon } from 'project/atoms/Icon';

const IconButton = ({ className, children, variant, onClick }) => {
  let classNames = [styles.button];
  if (variant) classNames.push(styles[variant]);
  if (className) classNames.push(className);
  let icon;

  switch (variant) {
    case 'faceit':
      icon = <FaceitIcon />;
      break;
    case 'steam':
      icon = <SteamIcon />
      break;
    default:
      break;
  }

  return (
      <Button onClick={onClick} isFullWidth>
          {icon}
          {children}
      </Button>
  );
};

IconButton.propTypes = {
  type: PropTypes.oneOf(['submit', 'button']),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['faceit', 'steam']),
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func
}

IconButton.defaultProps = {
  type: 'button',
  className: '',
  variant: null,
  onClick: () => {}
};

export default IconButton;