import PropTypes from 'prop-types';
import styles from './container.module.css';

const Container = ({ className, type, noPadding, center, isFullWidth, children }) => {
  let classNames = [styles.container];
  if (type) classNames.push(styles[type]);
  if (noPadding) classNames.push(styles.noPadding);
  if (center) classNames.push(styles.center);
  if (isFullWidth) classNames.push(styles.isFullWidth);
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
};

Container.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  noPadding: PropTypes.bool
}

Container.defaultProps = {
  className: '',
  type: null,
  noPadding: false,
  isFullWidth: false
};

export default Container;