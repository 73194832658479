import React, { useState } from 'react';
import styles from './showhide.module.css';

const ShowHide = ({ openLabel, closeLabel, children, isOpen, height }) => {
  const [show, toggleShow] = useState(isOpen);
  const classNames = [styles.showHide];
  if (show) classNames.push(styles.isOpen);

  return (
    <div className={classNames.join(' ')}>
      <div className={styles.content}>{children}</div>
      <button onClick={() => toggleShow(!show)} className={styles.label} type='button'>{show ? closeLabel : openLabel}</button>
    </div>
  );
};

ShowHide.defaultProps = {
  isOpen: false,
  openLabel: null,
  closeLabel: null
};

export default ShowHide;