import PropTypes from 'prop-types';

const Paragraph = ({ className, withTopMargin, children }) => {
  let classNames = [styles.paragraph];
  if (className) classNames.push(className);
  if (withTopMargin) classNames.push(styles.withTopMargin);

  return (
    <p className={`${classNames.join(' ')}`}>
      {children}
    </p>
  );
};

Paragraph.defaultProps = {
  className: ''
};

export default Paragraph;