import PropTypes from 'prop-types';

const Img = ({ src, alt, layout, width, height, className }) => {
  let classNames = [];
  if (className) classNames.push(className);

  return <img className={classNames.join(' ')} src={src} layout={!width && !height ? layout : null} width={width} height={height} alt={alt} />;
};

Img.propTypes = {
  layout: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  priority: PropTypes.bool,
  className: PropTypes.string
};

Img.defaultProps = {
  layout: 'fill',
  alt: ''
};

export default Img;