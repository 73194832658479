const Loader = ({ text, children }) => {
  return (
    <div className='loader is-loading'>
      <span className='loader__text'>{text}</span>
      <span className='loader__content'>{children}</span>
    </div>
  );
};

Loader.defaultProps = {
  text: 'Loading...'
};

export default Loader;