import NextLink from 'next/link';
import PropTypes from 'prop-types';
import styles from './button.module.css';

const Button = ({ className, href, type, isFullWidth, children, variant, onClick }) => {
  let classNames = [styles.button];
  if (variant) classNames.push(styles[variant]);
  if (isFullWidth) classNames.push(styles.isFullWidth);
  if (className) classNames.push(className);

  if (href) {
    return (
      <NextLink href={href}>
        <a className={classNames.join(' ')}>{children}</a>
      </NextLink>
    );
  }

  return (
    <button type={type} onClick={onClick} className={classNames.join(' ')}>{children}</button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button']),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['cta', 'secondary', 'outline', 'link']),
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func
}

Button.defaultProps = {
  type: 'button',
  className: '',
  isFullWidth: false,
  variant: null,
  onClick: () => {}
};

export default Button;