import PropTypes from 'prop-types';
import { Link } from 'project/atoms';

const Card = ({ title, abstract, image, href, members, className }) => {
  let classNames = ['card'];
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      {href && image && <Link href={href}>
        <img src={image} className="card-img-top" />
      </Link>}

      <div className="card-body">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="mb-2 name">
              {href ? <Link href={href}>
                {title}
              </Link> : title}
            </h4>

            <p className="card-text small text-muted">
              {abstract}
            </p>
          </div>


          <div className="col-auto">
            <div className="dropdown">
              <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fe fe-more-vertical"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <a href="#!" className="dropdown-item">
                  Action
                </a>
                <a href="#!" className="dropdown-item">
                  Another action
                </a>
                <a href="#!" className="dropdown-item">
                  Something else here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {members && <div className="card-footer card-footer-boxed">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="avatar-group">
                {members.map(member => {
                  const { name, image, slug } = member;

                  return (
                    <Link key={slug} href={`/app/profile/${slug}`} className={'avatar avatar-xs'}>
                      <img src={image} alt={name} className="avatar-img rounded-circle" />
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  abstract: PropTypes.string,
  image: PropTypes.string,
  href: PropTypes.string
};

Card.defaultProps = {
  className: '',
  card: {
    href: '#'
  },
  image: null
};

export default Card;