import PropTypes from 'prop-types';
import NextLink from 'next/link';
import styles from './link.module.css';

const Link = ({ href, variant, state, className, children }) => {
  let classNames = [styles.link];
  if (variant) classNames.push(styles[variant]);
  if (state) classNames.push(styles[state]);
  if (className) classNames.push(className);

  if (!href) {
    return null;
  }

  return (
    <NextLink href={href}>
      <a className={classNames.join(' ')}>{children}</a>
    </NextLink>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(['', 'isLoading', 'iDisabled']),
  isFullWidth: PropTypes.bool
}

Link.defaultProps = {
  className: '',
  state: ''
};

export default Link;