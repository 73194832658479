import { useRouter } from 'next/router'
import { Link } from 'project/atoms';

const TopSection = ({ title, category, nav }) => {
    const router = useRouter();

    return (
        <div className="header mt-md-5">
            <div className="header-body">
                <div className="row align-items-center">
                    <div className="col">

                    {category && <h6 className="header-pretitle">
                        {category}
                    </h6>}

                    <h1 className="header-title">
                        {title}
                    </h1>

                    </div>
                </div>

                {nav && <div className="row align-items-center">
                    <div className="col">
                        <ul className="nav nav-tabs nav-overflow header-tabs">
                            {nav.map((navItem, index) => {
                                const { href, text } = navItem;
                                const isActive = router.asPath.startsWith(href);

                                return (
                                    <li key={index} className="nav-item">
                                        <Link href={href} className={`nav-link ${isActive ? 'active' : ''}`}>
                                            {text}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default TopSection;