import PropTypes from 'prop-types';
import styles from './label.module.css';

const Label = ({ className, color, primary, children }) => {
  const classNames = [styles.label];
  if (className) classNames.push(className);
  if (color) classNames.push(styles[color]);
  if (primary) classNames.push(styles.primary);

  return (
    <label className={classNames.join(' ')}>{children}</label>
  );
};

Label.propTypes = {
  className: PropTypes.string
}

Label.defaultProps = {
  className: '',
  color: false
};

export default Label;