import PropTypes from 'prop-types';
import styles from './heading.module.css';

const Heading = ({ seoLevel, level, className, children, color }) => {
  const HeadingLevel = seoLevel ?? level;
  let classNames = [styles.heading, styles[level]];
  if (className) classNames.push(className);
  if (color) classNames.push(styles[`color-${color}`]);

  return (
    <HeadingLevel className={classNames.join(' ')}>
      {children}
    </HeadingLevel>
  );
};

Heading.propTypes = {
  level: PropTypes.string,
  seoLevel: PropTypes.string,
}

Heading.defaultProps = {
  level: 'h2',
  seoLevel: 'h2',
  className: ''
};

export default Heading;