import PropTypes from 'prop-types';
import styles from './text.module.css';

const Text = ({ state, small, inline, className, children, htmlText }) => {
  const ElemType = inline ? 'span' : 'div';
  let classNames = [];
  if (small) classNames.push(styles.small);
  if (state) classNames.push(styles[state]);
  if (className) classNames.push(className);
  
  if (htmlText) {
    return <ElemType className={classNames.join(' ')} dangerouslySetInnerHTML={{__html: htmlText}} />
  }

  return <ElemType className={classNames.join(' ')}>{children}</ElemType>;
};

PropTypes.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PropTypes.defaultProps = {
  state: '',
  className: ''
};

export default Text;