import { Container } from 'project/atoms';
import styles from './iconbox.module.css';

const IconBox = ({ className, color, size, children }) => (
  <Container className={`${styles.iconBox} ${className}`} noPadding>
    {children}
  </Container>
);

IconBox.defaultProps = {
  shadow: false,
  size: 'sm',
  className: '',
  color: 'default'
};

export default IconBox;