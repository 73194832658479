import PropTypes from 'prop-types';
import { Link } from 'project/atoms';

const CardTwo = ({ title, abstract, href, image }) => {
    return (
        <div className="card mb-3">
        <div className="card-body">
        <div className="row align-items-center">
            <div className="col-auto">

            {href ? 
                <Link href={href} className='avatar avatar-lg'>
                    <img src={image} alt={title} className="avatar-img rounded-circle" />
                </Link>
                :
                <img src={image} alt={title} className="avatar-img rounded-circle" />
            }
            </div>
            <div className="col ms-n2">
            <h4 className="mb-1">
                {href ?
                    <Link href={href} title={title}></Link>
                : title}
            </h4>

            {abstract && <p className="card-text small text-muted mb-1">
                {abstract}
            </p>}
            </div>
            
            <div className="col-auto">
            <div className="dropdown">
                <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" data-expanded="false">
                <i className="fe fe-more-vertical"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                <a href="#!" className="dropdown-item">
                    Action
                </a>
                <a href="#!" className="dropdown-item">
                    Another action
                </a>
                <a href="#!" className="dropdown-item">
                    Something else here
                </a>
                </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

CardTwo.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    abstract: PropTypes.string,
    image: PropTypes.string,
    href: PropTypes.string
};

CardTwo.defaultProps = {
    className: '',
    card: {
        href: '#'
    },
    image: null
};

export default CardTwo;