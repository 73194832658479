import PropTypes from 'prop-types';
import styles from './input.module.css';

const Input = ({ className, useRef, type, name, placeholder, onKeyUp, onBlur, onFocus }) => (
  <div className={`${styles.inputContainer} ${className}`}>
    <input className={`${styles.input}`}
      ref={useRef}
      type={type}
      name={name}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus}
      />
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'tel']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

Input.defaultProps = {
  className: '',
  type: 'text',
  name: '',
  placeholder: ''
};

export default Input;